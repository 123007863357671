import { Pipe, PipeTransform } from '@angular/core';
import {KommdataType} from '../models/kommdata.model';

@Pipe({
  name: 'kommDataTypeDisplay'
})
export class KommDataTypeDisplayPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
    case KommdataType.EMAIL:
      return 'E-Mail-Adresse';
    case KommdataType.MOBILE:
      return 'Mobilfunknummer';
    case KommdataType.LANDLINE:
      return 'Festnetznummer';
    }
  }

}
