import {Component, Input} from '@angular/core';

@Component({
  selector: 'dsmkd-message-tile',
  templateUrl: './message-tile.component.html',
  styleUrls: ['./message-tile.component.scss']
})
export class MessageTileComponent {
  @Input() badgeText: string;
  @Input() tileHeader: string;
  @Input() tileExplanation: string;
  @Input() typeOfMessageTile: string;

  getCardStyle() {
    return this.typeOfMessageTile === 'success' ? 'card-style-success' : 'card-style-error';
  }
}
