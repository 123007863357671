import {Pipe, PipeTransform} from '@angular/core';
import {KommdataUsageType} from '../models/kommdata.model';

@Pipe({
  name: 'contactDataType'
})
export class ContactDataTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): number {
    switch (value) {
    case KommdataUsageType.PRIVATE:
      return 0;
    case KommdataUsageType.BUSINESS:
      return 1;
    case KommdataUsageType.PRIVATEBUSINESS:
      return 2;
    default:
      return 3;
    }
  }
}
