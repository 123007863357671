<nx-card class="message-card padding-bottom-0" [ngClass]="getCardStyle()">
  <div *ngIf="typeOfMessageTile === 'success'; else error">
    <nx-badge type="positive" class="margin-bottom-12">{{badgeText}}</nx-badge>
  </div>
  <ng-template #error>
    <nx-badge vibrant type="critical" class="margin-bottom-12">{{badgeText}}</nx-badge>
    <span nxCopytext="medium" class="nx-margin-left-2xs nx-font-weight-400">{{'kommdata.error.headline.detailmessage.tile.kommdata.process' | translate}}</span>
  </ng-template>
    <h1 nxHeadline="subsection-xsmall" class="nx-margin-bottom-xs nx-font-weight-bold">
      {{tileHeader}}
    </h1>
  <div>
    <div class="u-text-align-left message-display padding-left-0">
      <nx-icon size="s" name="exclamation-circle-o" class="komm-data-change-success-icon margin-right-12"></nx-icon>
      <p nxCopytext="normal" class="nx-margin-bottom-s">{{tileExplanation}}</p>
    </div>
  </div>
</nx-card>
