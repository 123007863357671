import {BrowserModule, HammerModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AmslPersonService} from './core/services/amsl-person.service';
import {AuthService} from './core/services/auth/auth.service';
import {HttpErrorService} from './core/services/http-error.service';
import {LoadingAnimationService} from './core/services/loading-animation.service';
import {OverlayService} from './core/services/overlay.service';
import {StylesModule} from './styles/styles.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {WeweModule} from './wewe/wewe.module';
import {FilesUploadModule} from './files-upload/files-upload.module';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProcessIdService} from './core/services/process-id.service';
import {TrackingService} from './core/services/tracking.service';
import {ModelService} from './core/services/model.service';
import {AuthTokenInterceptor} from './interceptors/auth-token.interceptor';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {CommonHeadersInterceptor} from './interceptors/common-headers.interceptor';
import {AnimationInterceptor} from './interceptors/animation.interceptor';
import {UnAuthenticatedGuard} from './core/guards/un-authenticated.guard';
import {AppStateService} from './core/services/app-state.service';
import {WeweService} from './core/services/wewe.service';
import {FeedbackModule} from './shared/feedback/feedback.module';
import {ContactDataTypePipe} from './core/pipes/contact-data-type.pipe';
import {UrlApiService} from './core/services/url-api.service';
import {SabsPersonService} from './core/services/sabs-person.service';
import {KommDataResolver} from './core/resolver/komm-data.resolver';
import {KommDataChangeModule} from './komm-data-change/komm-data-change.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ContactDataTypePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StylesModule,
    TranslateModule,
    WeweModule,
    KommDataChangeModule,
    FilesUploadModule,
    SharedModule,
    FeedbackModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    UnAuthenticatedGuard,
    KommDataResolver,
    AmslPersonService,
    SabsPersonService,
    AuthService,
    HttpErrorService,
    LoadingAnimationService,
    OverlayService,
    ProcessIdService,
    TrackingService,
    ModelService,
    AppStateService,
    WeweService,
    {
      provide: APP_INITIALIZER,
      useFactory: (urlApiService: UrlApiService) => () => urlApiService.readAndClearOnce(),
      deps: [UrlApiService],
      multi: true
    },
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AnimationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonHeadersInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
