import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kommdataUsageTypeDisplay'
})
export class KommdataUsageTypeDisplayPipe implements PipeTransform {

  transform(value: string): unknown {
    switch (value) {
    case 'PRIVATE':
      return 'privat';
    case 'BUSINESS':
      return 'geschäftlich';
    case 'PRIVATEBUSINESS':
      return 'privat/geschäftlich';
    case 'UNKNOWN':
      return 'unbekannt';
    default:
      return 'unbekannt';
    }
  }
}
